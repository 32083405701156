@charset "UTF-8";
/* 余白 8の倍数 */
/*
page
*/
.page-header .jumbotron {
  padding: 85px 15px;
  /* height: 260px;

  @include mixin.tabletP {
    height: 400px;
  } */
}
.page-header .container-fluid {
  height: 100%;
}
.page-header .c-ttl-unit {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0;
  height: 100%;
}
.page-header .ttl-main-en {
  font-size: calc(30px + 30 * (100vw - 320px) / 1600);
  line-height: normal;
}
.page-header .ttl-sub-ja {
  line-height: normal;
  font-weight: 400;
}

/* ==[ .p-page-shop ]==================================== */
.p-page-shop .sec-inner {
  padding: 20px 0 80px;
}
@media not all and (min-width: 768px) {
  .p-page-shop .sec-inner {
    padding: 20px 0 60px;
  }
}
.p-page-shop .p-shop-card-unit {
  display: flex;
  gap: 30px 18px;
  justify-content: space-between;
  flex-wrap: nowrap;
}
@media not all and (min-width: 768px) {
  .p-page-shop .p-shop-card-unit {
    flex-wrap: wrap;
  }
}
.p-page-shop .p-card-wrap {
  box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  overflow: hidden;
  width: 33.3333333333%;
}
@media not all and (min-width: 768px) {
  .p-page-shop .p-card-wrap {
    width: 100%;
  }
}
.p-page-shop .shop-card .card-inner {
  padding: 15px 15px 30px;
}
.p-page-shop .shop-card .card-header {
  background-color: #DF121A;
  text-align: center;
}
.p-page-shop .shop-card .card-title {
  color: white;
  margin-bottom: 0;
  padding: 10px 15px;
}
.p-page-shop .shop-card .card-img {
  border-radius: 16px;
  overflow: hidden;
}
.p-page-shop .shop-card .card-img.ratio::before {
  padding-top: 67.7777%;
}
.p-page-shop .shop-card .card-img.ratio img {
  width: 100%;
  height: 100%;
}
.p-page-shop .shop-card .card-body {
  padding: 15px 0;
}
@media not all and (min-width: 768px) {
  .p-page-shop .shop-card .card-footer {
    padding: 0 20px;
  }
}
.p-page-shop .shop-card [class^=btn] + [class^=btn] {
  margin-top: 10px;
}
.p-page-shop .p-address-unit li {
  line-height: normal;
  padding-left: 26px;
  position: relative;
}
.p-page-shop .p-address-unit li::before {
  content: "";
  display: block;
  width: 16px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.p-page-shop .p-address-unit li + li {
  margin-top: 10px;
}
.p-page-shop .p-address-unit li.p-address::before {
  background: url(../img/top/shop/icon_mapping.svg) no-repeat center center;
  background-size: 100% auto;
  height: 22px;
  top: 3px;
  transform: translateY(0);
}
.p-page-shop .p-address-unit li.p-open::before {
  background: url(../img/top/shop/icon_clock.svg) no-repeat center center;
  background-size: 100% auto;
  height: 16px;
}
.p-page-shop .p-address-unit li.p-tel::before {
  background: url(../img/top/shop/icon_phone.svg) no-repeat center center;
  background-size: 100% auto;
  height: 16px;
}
.p-page-shop .p-address-unit li.p-tel a:hover {
  color: #0066C0;
  text-decoration: underline;
  opacity: 1;
}
.p-page-shop .contact-unit {
  margin-top: 30px;
}
.p-page-shop .contact-unit .contact-link {
  border-radius: 16px;
  box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.1);
  display: block;
  overflow: hidden;
}
.p-page-shop .contact-unit .contact-link:hover {
  box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.3);
}

.c-card-unit {
  display: flex;
  gap: 18px;
  margin-bottom: 50px;
}
@media not all and (min-width: 768px) {
  .c-card-unit {
    flex-wrap: wrap;
  }
}
.c-card-unit .card-wrap {
  width: calc(33.3333333333% - 12px);
}
@media not all and (min-width: 768px) {
  .c-card-unit .card-wrap {
    width: 100%;
  }
}
.c-card-unit .page-card {
  border-radius: 14px;
  box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  overflow: hidden;
  transition: 0.3s;
}
.c-card-unit .page-card .card-img {
  overflow: hidden;
}
.c-card-unit .page-card .card-img.ratio::before {
  padding-top: 63.4722%;
}
.c-card-unit .page-card .card-img.ratio img {
  width: 100%;
  height: 100%;
  transition: 0.3s;
}
.c-card-unit .page-card .card-body {
  padding: 20px 15px;
}
.c-card-unit .page-card .card-title {
  color: #333;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(18px + 2 * (100vw - 320px) / 1600);
  font-weight: 700;
  line-height: normal;
  padding-right: 15px;
  position: relative;
}
.c-card-unit .page-card .card-title::after {
  background: url(../img/common/icon_arrow_red.svg) no-repeat center center;
  background-size: 100% auto;
  content: "";
  display: block;
  width: 7px;
  height: 12px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.c-card-unit .page-card:hover {
  box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.3);
  transition: 0.3s;
  opacity: 1;
}
.c-card-unit .page-card:hover .card-img.ratio img {
  transform: scale(1.1);
  transition: 0.5s;
}

.info-card-wrap {
  margin-bottom: 30px;
}

.searchContainer__wrapper {
  margin-top: 100px;
  padding-bottom: 100px;
  background: #EDEDED;
  border-radius: 67px 67px 0 0;
}
.searchContainer__wrapper.usedcarLease__wrap {
  background-color: white;
  border-radius: 0;
  padding-bottom: 0;
}
.searchContainer__wrapper.usedcarLease__wrap .modContainer-inner {
  padding-bottom: 0;
}
.searchContainer__wrapper.usedcarLease__wrap .wp-pagenavi {
  margin-bottom: 0;
}
.searchContainer__wrapper.usedcarLease__wrap .sec-inner {
  padding-top: 0;
}
@media not all and (min-width: 768px) {
  .searchContainer__wrapper.usedcarLease__wrap .sec-inner {
    padding-top: 0;
  }
}

.searchResultBox {
  background: #fff;
  padding: 10px 15px;
  font-size: 24px;
  font-weight: bold;
  border: 1px solid #707070;
  border-radius: 6px;
  margin-bottom: 20px;
}
.searchResultBox__inner {
  display: flex;
  align-items: center;
}
@media not all and (min-width: 768px) {
  .searchResultBox__inner {
    flex-wrap: wrap;
  }
}
@media not all and (min-width: 768px) {
  .searchResultBox__inner-item {
    width: 100%;
  }
}
.searchResultBox__inner-item:last-child {
  margin-left: auto;
}
.searchResultBox__inner-item .modFlex {
  display: flex;
  align-items: center;
  gap: 0 16px;
}

.reserveContainer {
  margin-bottom: 100px;
}

/* 20240523追加 */
.p-top-search {
  color: white;
  /* .sec-inner {
    padding-top: 0;
  } */
}
.p-top-search .c-ttl-unit {
  margin-bottom: 0;
  padding: 0 30px;
}
.p-top-search .container-full-outer {
  position: relative;
  overflow: hidden;
}
.p-top-search .container-full-outer .search-bg-img {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
}
.p-top-search .container-full-outer .search-bg-img[src$=".svg"] {
  width: 100%;
  max-width: 100%;
}
.p-top-search .container-full-outer .c-ttl-unit {
  margin-bottom: 30px;
}
.p-top-search .container-full {
  background: #ff3f05;
  background: linear-gradient(45deg, #ff3f05 0%, #df121a 80%);
  border-radius: 67px;
  padding: 60px 0;
  z-index: 0;
}
@media not all and (min-width: 992px) {
  .p-top-search .container-full {
    border-radius: 40px;
  }
}
@media not all and (min-width: 768px) {
  .p-top-search .container-full {
    border-radius: 20px;
  }
}
.p-top-search .container-fluid {
  position: relative;
  z-index: 2;
}
.p-top-search .input-group {
  background-color: white;
  border-radius: 30px;
  box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.1) inset;
  display: flex;
  align-items: center;
  padding: 0 15px;
  margin: 0 auto;
  width: 760px;
  max-width: 100%;
}
.p-top-search .input-group .form-control {
  background-color: transparent;
  border: none;
  border-radius: 0;
  padding: 0 20px;
  height: 60px;
}
.p-top-search .input-group .form-control:focus {
  outline: none !important;
}
.p-top-search .input-group .input-group-text {
  background-color: transparent;
  border: none;
}
.p-top-search .input-group .input-group-text img {
  width: 28px;
  height: 28px;
}
@media not all and (min-width: 768px) {
  .p-top-search .p-link-block .ttl-ja {
    margin-bottom: 10px;
  }
}
.p-top-search .custom-btn-wrap {
  margin-top: 30px;
}
.p-top-search .custom-btn-wrap .custom-btn {
  border-radius: 16px;
  box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.1);
}
.p-top-search .custom-btn-wrap .custom-btn.bg-transparent {
  background-color: transparent;
  padding: 0;
  width: auto;
}

.usedcarLeaseBlock [class^=col-] {
  padding: 0;
}
.usedcarLeaseBlock .sliderBlock {
  margin: 0 auto 30px;
  width: 742px;
  max-width: 100%;
  position: relative;
  overflow: hidden;
}
.usedcarLeaseBlock .sliderBlock .swiper-slide-item {
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  position: relative;
  overflow: hidden;
  /* &::before {
    content: "";
    display: block;
  } */
}
.usedcarLeaseBlock .sliderBlock .swiper-slide-item img {
  /* height: 100%; */
  /* position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%); */
}
.usedcarLeaseBlock .sliderBlock__main {
  background-color: #EDEDED;
  padding: 0 40px;
  user-select: none;
  overflow: hidden;
}
@media not all and (min-width: 768px) {
  .usedcarLeaseBlock .sliderBlock__main {
    padding: 0 30px;
  }
}
.usedcarLeaseBlock .sliderBlock__main-inner {
  overflow: hidden;
}
.usedcarLeaseBlock .sliderBlock__main .swiper-slide-item {
  height: 495px;
  /* &::before {
    padding-top: 75%;
  } */
}
@media not all and (min-width: 768px) {
  .usedcarLeaseBlock .sliderBlock__main .swiper-slide-item {
    height: 210px;
  }
}
.usedcarLeaseBlock .sliderBlock__main .swiper-slide-item img {
  width: auto;
}
.usedcarLeaseBlock .sliderBlock__thumb {
  margin-top: 30px;
}
@media not all and (min-width: 768px) {
  .usedcarLeaseBlock .sliderBlock__thumb {
    margin-top: 10px;
  }
}
.usedcarLeaseBlock .sliderBlock__thumb .swiper-slide {
  border: 2px white solid;
}
.usedcarLeaseBlock .sliderBlock__thumb .swiper-slide-item {
  height: 94px;
  /* &::before {
    padding-top: 66.1765%;
  } */
}
@media not all and (min-width: 768px) {
  .usedcarLeaseBlock .sliderBlock__thumb .swiper-slide-item {
    height: 42px;
  }
}
.usedcarLeaseBlock .sliderBlock__thumb .swiper-slide-item img {
  /* width: 100%; */
  width: auto;
}
.usedcarLeaseBlock .sliderBlock__thumb .swiper-slide.swiper-slide-thumb-active {
  border-color: #aaa;
}
.usedcarLeaseBlock .sliderBlock .swiper-button-prev,
.usedcarLeaseBlock .sliderBlock .swiper-button-next {
  width: 40px;
  height: 40px;
  transform: translateY(-50%);
}
.usedcarLeaseBlock .sliderBlock .swiper-button-prev::after,
.usedcarLeaseBlock .sliderBlock .swiper-button-next::after {
  width: 40px;
  height: 40px;
}
.usedcarLeaseBlock__plan {
  margin-top: 40px;
}
.usedcarLeaseBlock__plan .modFlex {
  gap: 0 20px;
  margin-bottom: 50px;
}
@media not all and (min-width: 768px) {
  .usedcarLeaseBlock__plan .modFlex {
    gap: 15px 0;
    flex-wrap: wrap;
  }
}
.usedcarLeaseBlock__plan .modFlex__item {
  width: 50%;
}
@media not all and (min-width: 768px) {
  .usedcarLeaseBlock__plan .modFlex__item {
    width: 100%;
  }
}
.usedcarLeaseBlock__option {
  margin-top: 60px;
}
@media not all and (min-width: 768px) {
  .usedcarLeaseBlock__option {
    margin-top: 30px;
  }
}
.usedcarLeaseBlock__option .option__tab {
  border-bottom: none;
  justify-content: center;
  gap: 0 20px;
  width: 100%;
}
.usedcarLeaseBlock__option .option__tab .nav-item {
  padding-bottom: 15px;
}
@media not all and (min-width: 768px) {
  .usedcarLeaseBlock__option .option__tab .nav-item {
    width: 100%;
  }
}
.usedcarLeaseBlock__option .option__tab .nav-link {
  border: 3px #0066C0 dashed;
  border-radius: 100vw;
  color: #0066C0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  min-height: 90px;
  width: 495px;
  max-width: 100%;
  position: relative;
}
@media not all and (min-width: 768px) {
  .usedcarLeaseBlock__option .option__tab .nav-link {
    min-height: 70px;
    width: 100%;
  }
}
.usedcarLeaseBlock__option .option__tab .nav-link.active {
  background-color: #0066C0;
  color: white;
}
.usedcarLeaseBlock__option .option__tab .nav-link.active::after {
  border-style: solid;
  border-right: 23px solid transparent;
  border-left: 23px solid transparent;
  border-top: 16px solid #0066C0;
  border-bottom: 0;
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  bottom: -16px;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.5s ease;
}
@media not all and (min-width: 768px) {
  .usedcarLeaseBlock__option .option__tab .nav-link.active::after {
    display: none;
  }
}
.usedcarLeaseBlock__option .option__tab .nav-link.active:hover {
  cursor: default;
  opacity: 1;
}
.usedcarLeaseBlock__option .option__tab .nav-link .tab__link-ttl, .usedcarLeaseBlock__option .option__tab .nav-link .tab__link-plan {
  font-size: clamp(1.25rem, 1.2rem + 0.25vw, 1.5rem);
  font-weight: 700;
  line-height: 1.333;
}
.usedcarLeaseBlock__option .option__tab .nav-link .tab__link-plan {
  display: flex;
  align-items: baseline;
}
.usedcarLeaseBlock__option .option__tab .nav-link .tab__link-plan::before, .usedcarLeaseBlock__option .option__tab .nav-link .tab__link-plan::after {
  display: block;
  font-size: clamp(1rem, 0.975rem + 0.13vw, 1.125rem);
}
.usedcarLeaseBlock__option .option__tab .nav-link .tab__link-plan::before {
  content: "月々";
}
.usedcarLeaseBlock__option .option__tab .nav-link .tab__link-plan::after {
  content: "円(税込)";
}
.usedcarLeaseBlock__option .option__tabContent {
  margin-top: 10px;
}
.usedcarLeaseBlock__option .modTitle {
  display: flex;
  flex-direction: column;
  gap: 20px 0;
}
@media not all and (min-width: 768px) {
  .usedcarLeaseBlock__option .modTitle {
    gap: 10px 0;
  }
}
.usedcarLeaseBlock__option .optionBlock {
  border-radius: 12px;
  padding: 40px;
}
@media not all and (min-width: 768px) {
  .usedcarLeaseBlock__option .optionBlock {
    padding: 20px;
  }
}
.usedcarLeaseBlock__option .optionBlock .modFlex {
  align-items: flex-start;
  gap: 0 10px;
  width: 100%;
  max-width: 100%;
}
@media not all and (min-width: 768px) {
  .usedcarLeaseBlock__option .optionBlock .modFlex {
    flex-wrap: wrap;
    gap: 0;
  }
}
.usedcarLeaseBlock__option .optionBlock .modFlex__item {
  width: 33.3333333333%;
}
@media not all and (min-width: 768px) {
  .usedcarLeaseBlock__option .optionBlock .modFlex__item {
    width: 100%;
  }
}
.usedcarLeaseBlock__option .optionBlock .modFlex__item .option__list li {
  font-size: clamp(1rem, 0.95rem + 0.25vw, 1.25rem);
  font-weight: 700;
}
.usedcarLeaseBlock__option .optionBlock .modFlex__item .option__list li::before {
  content: "・";
  display: inline-block;
}
.usedcarLeaseBlock__option .modBtnBlock {
  margin-top: 30px;
}
.usedcarLeaseBlock__request {
  margin-top: 100px;
}
@media not all and (min-width: 768px) {
  .usedcarLeaseBlock__request {
    margin-top: 30px;
  }
}
.usedcarLeaseBlock__request .container-full {
  background-color: #EDEDED;
  margin: 0 calc(50% - 50vw);
  max-width: inherit;
  width: 100vw;
}
.usedcarLeaseBlock__request .container-fluid {
  padding: 45px 15px;
  position: relative;
}
@media not all and (min-width: 768px) {
  .usedcarLeaseBlock__request .container-fluid {
    padding: 30px 15px;
  }
}
.usedcarLeaseBlock__request .container-fluid::before, .usedcarLeaseBlock__request .container-fluid::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  z-index: 0;
}
@media not all and (min-width: 768px) {
  .usedcarLeaseBlock__request .container-fluid::before, .usedcarLeaseBlock__request .container-fluid::after {
    display: none;
  }
}
.usedcarLeaseBlock__request .container-fluid::before {
  background: url(../img/page/usedcar_lease/request_bg1.svg) no-repeat center center;
  background-size: 100% auto;
  width: 104px;
  height: 320px;
  left: calc(10vw - 16px);
}
.usedcarLeaseBlock__request .container-fluid::after {
  background: url(../img/page/usedcar_lease/request_bg2.svg) no-repeat center center;
  background-size: 100% auto;
  width: 178px;
  height: 202px;
  right: calc(10vw - 16px);
}
.usedcarLeaseBlock__request .modTitle {
  display: flex;
  flex-direction: column;
  gap: 16px 0;
}
@media not all and (min-width: 768px) {
  .usedcarLeaseBlock__request .modTitle {
    gap: 10px 0;
  }
}
.usedcarLeaseBlock__request .modTitle .ttl {
  line-height: normal;
  font-size: clamp(1.125rem, 0.989rem + 0.68vw, 1.5rem);
  font-weight: 700;
}
.usedcarLeaseBlock__request .modTitle .subttl {
  line-height: normal;
}
@media not all and (min-width: 768px) {
  .usedcarLeaseBlock__request .modTitle .subttl {
    text-align: left;
  }
}
.usedcarLeaseBlock__contact {
  margin: 50px auto 100px;
}
@media not all and (min-width: 768px) {
  .usedcarLeaseBlock__contact {
    margin: 30px auto 50px;
  }
}
.usedcarLeaseBlock__contact .modBtnBlock {
  display: flex;
  align-items: center;
  gap: 0 16px;
  width: 100%;
}
@media not all and (min-width: 768px) {
  .usedcarLeaseBlock__contact .modBtnBlock {
    gap: 20px 0;
    flex-wrap: wrap;
  }
}
.usedcarLeaseBlock__contact .modBtnBlock__item {
  background-color: white;
  box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  gap: 20px 0;
  justify-content: center;
  flex-direction: column;
  line-height: normal;
  padding: 30px 16px;
  min-height: 174px;
  width: 50%;
}
@media not all and (min-width: 768px) {
  .usedcarLeaseBlock__contact .modBtnBlock__item {
    gap: 10px 0;
    width: 100%;
  }
}
.usedcarLeaseBlock__contact .modBtnBlock__item-ttl {
  font-size: clamp(1.125rem, 1.05rem + 0.38vw, 1.5rem);
  font-weight: 700;
  text-align: center;
}
.usedcarLeaseBlock__contact .modBtnBlock__item .btn {
  margin-top: 0;
}

.cost__card {
  background-color: #EFF5FB;
  border-radius: 12px;
  display: flex;
  gap: 16px 0;
  flex-direction: column;
  padding: 20px 16px;
  margin-top: 30px;
}
.cost__card-header {
  background-color: #0066C0;
  border-radius: 12px;
  padding: 10px;
  text-align: center;
}
.cost__card-title {
  color: white;
  font-size: clamp(1rem, 0.818rem + 0.91vw, 1.5rem);
  font-weight: 700;
}
.cost__card-body .modFlex {
  gap: 0 14px;
  justify-content: center;
}
@media not all and (min-width: 768px) {
  .cost__card-body .modFlex {
    flex-wrap: wrap;
    gap: 14px;
  }
}
.cost__card-body .modFlex li {
  width: 20%;
}
@media not all and (min-width: 768px) {
  .cost__card-body .modFlex li {
    width: calc(50% - 10px);
  }
}

[class^=toggle_panel] .modFlex {
  gap: 0 18px;
  align-items: flex-start;
}
@media not all and (min-width: 768px) {
  [class^=toggle_panel] .modFlex {
    gap: 0;
    flex-wrap: wrap;
  }
}
[class^=toggle_panel] .modFlex__item {
  border-top: 1px #aaa solid;
  width: calc(50% - 10px);
}
@media not all and (min-width: 768px) {
  [class^=toggle_panel] .modFlex__item {
    width: 100%;
  }
}
@media not all and (min-width: 768px) {
  [class^=toggle_panel] .modFlex__item:last-child {
    border-top: none;
  }
}

/* 20240711追加 */
#page .myBlockFMNews {
  padding-bottom: 80px;
}
#page .myBlockFMNews .myBlockCard {
  display: flex;
  gap: 30px 15px;
  flex-wrap: wrap;
}
@media not all and (min-width: 768px) {
  #page .myBlockFMNews .myBlockCard {
    gap: 20px 0;
  }
}
#page .myBlockFMNews .myBlockCard .card {
  border-radius: 12px;
  box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.1);
  color: #333;
  gap: 10px 0;
  padding: 24px 15px;
  text-decoration: none;
  width: calc(25% - 12px);
}
@media not all and (min-width: 992px) {
  #page .myBlockFMNews .myBlockCard .card {
    width: calc(33.3333333333% - 10px);
  }
}
@media not all and (min-width: 768px) {
  #page .myBlockFMNews .myBlockCard .card {
    border-radius: 8px;
    padding: 20px 10px;
    /* width: calc(100% / 2 - 5px); */
    width: 100%;
  }
}
#page .myBlockFMNews .myBlockCard .card-img {
  padding: 0 30px;
  text-align: center;
}
@media not all and (min-width: 768px) {
  #page .myBlockFMNews .myBlockCard .card-img {
    padding: 0 20px;
  }
}
#page .myBlockFMNews .myBlockCard .card-img img {
  width: auto;
}
#page .myBlockFMNews .myBlockCard .card-title {
  font-size: calc(16px + 6 * (100vw - 320px) / 1600);
  text-align: center;
}
#page .myBlockFMNews .myBlockCard .card-title.-new {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 5px;
}
@media not all and (min-width: 768px) {
  #page .myBlockFMNews .myBlockCard .card-title.-new {
    gap: 0 4px;
  }
}
#page .myBlockFMNews .myBlockCard .card-title.-new::before {
  content: "NEW";
  background-color: #DF121A;
  color: white;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-size: calc(10px + 6 * (100vw - 320px) / 1600);
  font-weight: 800;
  padding: 0 5px;
  min-width: calc(48px + 6 * (100vw - 320px) / 1600);
}