@use '../foundation/variables' as variables;
@use '../foundation/mixin' as mixin;

/*
page
*/

.page-header {
  .jumbotron {
    padding: 85px 15px;
    /* height: 260px;

    @include mixin.tabletP {
      height: 400px;
    } */
  }

  .container-fluid {
    height: 100%;
  }

  .c-ttl-unit {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0;
    height: 100%;
  }

  .ttl-main-en {
    font-size: calc(30px + 30 * (100vw - 320px) / 1600);
    line-height: normal;
  }

  .ttl-sub-ja {
    line-height: normal;
    font-weight: 400;
  }
}

/* ==[ .p-page-shop ]==================================== */
.p-page-shop {

  .sec-inner {
    padding: 20px 0 80px;

    @include mixin.tabletP {
      padding: 20px 0 60px;
    }
  }

  .p-shop-card-unit {
    display: flex;
    gap: 30px 18px;
    justify-content: space-between;
    flex-wrap: nowrap;

    @include mixin.tabletP {
      flex-wrap: wrap;
    }
  }

  .p-card-wrap {
    box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    overflow: hidden;
    width: calc(100% / 3);

    @include mixin.tabletP {
      width: 100%;
    }
  }

  .shop-card {

    .card-inner {
      padding: 15px 15px 30px;
    }

    .card-header {
      background-color: variables.$COLOR_MAIN;
      text-align: center;
    }

    .card-title {
      color: white;
      margin-bottom: 0;
      padding: 10px 15px;
    }

    .card-img {
      border-radius: 16px;
      overflow: hidden;

      &.ratio {
        &::before {
          padding-top: 67.7777%;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .card-body {
      padding: 15px 0;
    }

    .card-footer {
      @include mixin.tabletP {
        padding: 0 20px;
      }
    }

    [class^="btn"] {

      +[class^="btn"] {
        margin-top: 10px;
      }
    }

  }

  .p-address-unit {

    li {
      line-height: normal;
      padding-left: 26px;
      position: relative;

      &::before {
        content: "";
        display: block;
        width: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }

      +li {
        margin-top: 10px;
      }

      &.p-address {

        &::before {
          background: url(#{mixin.set_image_path('top/shop/icon_mapping.svg')}) no-repeat center center;
          background-size: 100% auto;
          height: 22px;
          top: 3px;
          transform: translateY(0);
        }
      }

      &.p-open {

        &::before {
          background: url(#{mixin.set_image_path('top/shop/icon_clock.svg')}) no-repeat center center;
          background-size: 100% auto;
          height: 16px;
        }
      }

      &.p-tel {

        &::before {
          background: url(#{mixin.set_image_path('top/shop/icon_phone.svg')}) no-repeat center center;
          background-size: 100% auto;
          height: 16px;
        }

        a {
          &:hover {
            color: map_get(variables.$COLOR, blue);
            text-decoration: underline;
            opacity: 1;
          }
        }
      }
    }
  }

  .contact-unit {
    margin-top: 30px;

    .contact-link {
      border-radius: 16px;
      box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.1);
      display: block;
      overflow: hidden;

      &:hover {
        box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.3);
      }
    }
  }
}

.c-card-unit {
  display: flex;
  gap: 18px;
  margin-bottom: 50px;

  @include mixin.tabletP {
    flex-wrap: wrap;
  }

  .card-wrap {
    width: calc(100% / 3 - 12px);

    @include mixin.tabletP {
      width: 100%;
    }

  }

  .page-card {
    border-radius: 14px;
    box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, .1);
    text-decoration: none;
    overflow: hidden;
    transition: .3s;

    .card-img {
      overflow: hidden;

      &.ratio {

        &::before {
          padding-top: 63.4722%;
        }

        img {
          width: 100%;
          height: 100%;
          transition: .3s;
        }
      }
    }

    .card-body {
      padding: 20px 15px;
    }

    .card-title {
      color: variables.$COLOR_TEXT;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: calc(18px + 2 * (100vw - 320px) / 1600);
      font-weight: 700;
      line-height: normal;
      padding-right: 15px;
      position: relative;

      &::after {
        background: url(#{mixin.set_common_path('icon_arrow_red.svg')}) no-repeat center center;
        background-size: 100% auto;
        content: "";
        display: block;
        width: 7px;
        height: 12px;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }
    }

    &:hover {
      box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, .3);
      transition: .3s;
      opacity: 1;

      .card-img {

        &.ratio {

          img {
            transform: scale(1.1);
            transition: .5s;
          }
        }
      }
    }
  }
}

.info-card-wrap {
  margin-bottom: 30px;
}

.searchContainer__wrapper {
  margin-top: 100px;
  padding-bottom: 100px;
  background: #EDEDED;
  border-radius: 67px 67px 0 0;

  &.usedcarLease__wrap {
    background-color: white;
    border-radius: 0;
    padding-bottom: 0;

    .modContainer-inner {
      padding-bottom: 0;
    }

    .wp-pagenavi {
      margin-bottom: 0;
    }

    .sec-inner {
      padding-top: 0;

      @include mixin.tabletP {
        padding-top: 0;
      }
    }
  }

}

.searchResultBox {
  background: #fff;
  padding: 10px 15px;
  font-size: 24px;
  font-weight: bold;
  border: 1px solid #707070;
  border-radius: 6px;
  margin-bottom: 20px;

  &__inner {
    display: flex;
    align-items: center;

    @include mixin.tabletP {
      flex-wrap: wrap;
    }

    &-item {

      @include mixin.tabletP {
        width: 100%;
      }

      &:last-child {
        margin-left: auto;
      }

      .modFlex {
        display: flex;
        align-items: center;
        gap: 0 16px;
      }
    }
  }
}

.reserveContainer {
  margin-bottom: 100px;
}

/* 20240523追加 */
.p-top-search {
  color: white;

  /* .sec-inner {
    padding-top: 0;
  } */

  .c-ttl-unit {
    margin-bottom: 0;
    padding: 0 30px;
  }

  .container-full-outer {
    position: relative;
    overflow: hidden;

    .search-bg-img {
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 1;

      &[src$=".svg"] {
        width: 100%;
        max-width: 100%;
      }
    }

    .c-ttl-unit {
      margin-bottom: 30px;
    }
  }

  .container-full {
    background: rgb(255,63,5);
    background: linear-gradient(45deg, rgba(255,63,5,1) 0%, rgba(223,18,26,1) 80%);
    border-radius: 67px;
    padding: 60px 0;
    z-index: 0;

    @include mixin.tabletL {
      border-radius: 40px;
    }

    @include mixin.tabletP {
      border-radius: 20px;
    }
  }

  .container-fluid {
    position: relative;
    z-index: 2;
  }

  .input-group {
    background-color: white;
    border-radius: 30px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, .1) inset;
    display: flex;
    align-items: center;
    padding: 0 15px;
    margin: 0 auto;
    width: 760px;
    max-width: 100%;

    .form-control {
      background-color: transparent;
      border: none;
      border-radius: 0;
      padding: 0 20px;
      height: 60px;

      &:focus {
        outline: none !important;
      }
    }

    .input-group-text {
      background-color: transparent;
      border: none;

      img {
        width: 28px;
        height: 28px;
      }
    }
  }

  .p-link-block {

    .ttl-ja {
      @include mixin.tabletP {
        margin-bottom: 10px;
      }
    }
  }

  .custom-btn-wrap {
    margin-top: 30px;

    .custom-btn {
      border-radius: 16px;
      box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.1);

      &.bg-transparent {
        background-color: transparent;
        padding: 0;
        width: auto;
      }
    }

  }
}

.usedcarLeaseBlock {

  [class^="col-"] {
    padding: 0;
  }

  .sliderBlock {
    margin: 0 auto 30px;
    width: 742px;
    max-width: 100%;
    position: relative;
    overflow: hidden;

    .swiper-slide-item {
      background-color: white;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      position: relative;
      overflow: hidden;

      /* &::before {
        content: "";
        display: block;
      } */

      img {
        /* height: 100%; */
        /* position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%); */
      }
    }

    &__main {
      background-color: map_get(variables.$COLOR, gray);
      padding: 0 40px;
      user-select: none;
      overflow: hidden;

      @include mixin.tabletP {
        padding: 0 30px;
      }

      &-inner {
        overflow: hidden;
      }

      .swiper-slide {

        &-item {
          height: 495px;


          @include mixin.tabletP {
            height: 210px;
          }


          /* &::before {
            padding-top: 75%;
          } */

          img {
            width: auto;
          }

        }
      }
    }

    &__thumb {
      margin-top: 30px;

      @include mixin.tabletP {
        margin-top: 10px;
      }

      .swiper-slide {
        border: 2px white solid;

        &-item {
          height: 94px;

          @include mixin.tabletP {
            height: 42px;
          }

          /* &::before {
            padding-top: 66.1765%;
          } */

          img {
            /* width: 100%; */
            width: auto;
          }
        }

        &.swiper-slide-thumb-active {
          border-color: #aaa;
        }
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      width: 40px;
      height: 40px;
      transform: translateY(-50%);

      &::after {
        width: 40px;
        height: 40px;
      }
    }
  }

  &__plan {
    margin-top: 40px;

    .modFlex {
      gap: 0 20px;
      margin-bottom: 50px;

      @include mixin.tabletP {
        gap: 15px 0;
        flex-wrap: wrap;
      }

      &__item {
        width: calc(100% / 2);


      @include mixin.tabletP {
        width: 100%;
      }
      }

    }
  }

  &__option {
    margin-top: 60px;

    @include mixin.tabletP {
      margin-top: 30px;
    }

    .option__tab {
      border-bottom: none;
      justify-content: center;
      gap: 0 20px;
      width: 100%;

      .nav-item {
        padding-bottom: 15px;

        @include mixin.tabletP {
          width: 100%;
        }
      }

      .nav-link {
        border: 3px map_get(variables.$COLOR, blue) dashed;
        border-radius: 100vw;
        color: map_get(variables.$COLOR, blue);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        min-height: 90px;
        width: 495px;
        max-width: 100%;
        position: relative;

        @include mixin.tabletP {
          min-height: 70px;
          width: 100%;
        }

        &.active {
          background-color: map_get(variables.$COLOR, blue);
          color: white;

          &::after {
            border-style: solid;
            border-right: 23px solid transparent;
            border-left: 23px solid transparent;
            border-top: 16px solid map_get(variables.$COLOR, blue);
            border-bottom: 0;
            content: "";
            display: block;
            width: 0;
            height: 0;
            position: absolute;
            bottom: -16px;
            left: 50%;
            transform: translateX(-50%);
            transition: .5s ease;

            @include mixin.tabletP {
              display: none;
            }
          }

          &:hover {
            cursor: default;
            opacity: 1;
          }
        }

        .tab__link {
          &-ttl,
          &-plan {
            font-size: clamp(1.25rem, 1.2rem + 0.25vw, 1.5rem);
            font-weight: 700;
            line-height: 1.333;
          }

          &-plan {
            display: flex;
            align-items: baseline;

            &::before,
            &::after {
              display: block;
              font-size: clamp(1rem, 0.975rem + 0.13vw, 1.125rem);
            }

            &::before {
              content: "月々";
            }

            &::after {
              content: "円(税込)";
            }
          }
        }
      }

    }

    .option__tabContent {
      margin-top: 10px;
    }

    .modTitle {
      display: flex;
      flex-direction: column;
      gap: 20px 0;

      @include mixin.tabletP {
        gap: 10px 0;
      }
    }

    .optionBlock {
      border-radius: 12px;
      padding: 40px;

      @include mixin.tabletP {
        padding: 20px;
      }

      .modFlex {
        align-items: flex-start;
        gap: 0 10px;
        width: 100%;
        max-width: 100%;

        @include mixin.tabletP {
          flex-wrap: wrap;
          gap: 0;
        }

        &__item {
          width: calc(100% / 3);

          @include mixin.tabletP {
            width: 100%;
          }

          .option__list {

            li {
              font-size: clamp(1rem, 0.95rem + 0.25vw, 1.25rem);
              font-weight: 700;

              &::before {
                content: "・";
                display: inline-block;
              }
            }
          }
        }
      }
    }

    .modBtnBlock {
      margin-top: 30px;
    }
  }

  &__request {
    margin-top: 100px;

    @include mixin.tabletP {
      margin-top: 30px;
    }

    .container-full {
      background-color: map_get(variables.$COLOR, gray);
      margin: 0 calc(50% - 50vw);
      max-width: inherit;
      width: 100vw;
    }

    .container-fluid {
      padding: 45px 15px;
      position: relative;

      @include mixin.tabletP {
        padding: 30px 15px;
      }


      &::before,
      &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        z-index: 0;

        @include mixin.tabletP {
          display: none;
        }
      }

      &::before {
        background: url(#{mixin.set_image_path('page/usedcar_lease/request_bg1.svg')}) no-repeat center center;
        background-size: 100% auto;
        width: 104px;
        height: 320px;
        left: calc(10vw - 16px);
      }

      &::after {
        background: url(#{mixin.set_image_path('page/usedcar_lease/request_bg2.svg')}) no-repeat center center;
        background-size: 100% auto;
        width: 178px;
        height: 202px;
        right: calc(10vw - 16px);
      }

    }

    .modTitle {
      display: flex;
      flex-direction: column;
      gap: 16px 0;

      @include mixin.tabletP {
        gap: 10px 0;
      }

      .ttl {
        line-height: normal;
        font-size: clamp(1.125rem, 0.989rem + 0.68vw, 1.5rem);
        font-weight: 700;
      }

      .subttl {
        line-height: normal;

        @include mixin.tabletP {
          text-align: left;
        }
      }
    }
  }

  &__contact {
    margin: 50px auto 100px;

    @include mixin.tabletP {
      margin: 30px auto 50px;
    }

    .modBtnBlock {
      display: flex;
      align-items: center;
      gap: 0 16px;
      width: 100%;

      @include mixin.tabletP {
        gap: 20px 0;
        flex-wrap: wrap;
      }

      &__item {
        background-color: white;
        box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.1);
        display: flex;
        gap: 20px 0;
        justify-content: center;
        flex-direction: column;
        line-height: normal;
        padding: 30px 16px;
        min-height: 174px;
        width: calc(100% / 2);

        @include mixin.tabletP {
          gap: 10px 0;
          width: 100%;
        }

        &-ttl {
          font-size: clamp(1.125rem, 1.05rem + 0.38vw, 1.5rem);
          font-weight: 700;
          text-align: center;
        }

        .btn {
          margin-top: 0;
        }
      }
    }
  }
}

.cost__card {
  background-color: #EFF5FB;
  border-radius: 12px;
  display: flex;
  gap: 16px 0;
  flex-direction: column;
  padding: 20px 16px;
  margin-top: 30px;

  &-header {
    background-color: map_get(variables.$COLOR, blue);
    border-radius: 12px;
    padding: 10px;
    text-align: center;
  }

  &-title {
    color: white;
    font-size: clamp(1rem, 0.818rem + 0.91vw, 1.5rem);
    font-weight: 700;
  }

  &-body {
    .modFlex {
      gap: 0 14px;
      justify-content: center;

      @include mixin.tabletP {
        flex-wrap: wrap;
        gap: 14px;
      }

      li {
        width: calc(100% / 5);

        @include mixin.tabletP {
          width: calc(100% / 2 - 10px);
        }

      }
    }
  }
}

[class^="toggle_panel"] {
  .modFlex {
    gap: 0 18px;
    align-items: flex-start;

    @include mixin.tabletP {
      gap: 0;
      flex-wrap: wrap;
    }

    &__item {
      border-top: 1px #aaa solid;
      width: calc(100% / 2 - 10px);

      @include mixin.tabletP {
        width: 100%;
      }

      &:last-child {

        @include mixin.tabletP {

          border-top: none;
        }
      }
    }
  }
}

/* 20240711追加 */

#page {
  .myBlockFMNews {
    padding-bottom: 80px;

    .myBlockCard {
      display: flex;
      gap: 30px 15px;
      flex-wrap: wrap;

      @include mixin.tabletP {
        gap: 20px 0;
      }

      .card {
        border-radius: 12px;
        box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.1);
        color: variables.$COLOR_TEXT;
        gap: 10px 0;
        padding: 24px 15px;
        text-decoration: none;
        width: calc(100% / 4 - 12px);

        @include mixin.tabletL {
          width: calc(100% / 3 - 10px);
        }

        @include mixin.tabletP {
          border-radius: 8px;
          padding: 20px 10px;
          /* width: calc(100% / 2 - 5px); */
          width: 100%;
        }

        &-img {
          padding: 0 30px;
          text-align: center;

          @include mixin.tabletP {
            padding: 0 20px;
          }

          img {
            width: auto;
          }
        }

        &-title {
          font-size: calc(16px + 6 * (100vw - 320px) / 1600);
          text-align: center;

          &.-new {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0 5px;

            @include mixin.tabletP {
              gap: 0 4px;
            }

            &::before {
              content: "NEW";
              background-color: variables.$COLOR_MAIN;
              color: white;
              font-family: variables.$FONT_ALPHA;
              font-size: calc(10px + 6 * (100vw - 320px) / 1600);
              font-weight: 800;
              padding: 0 5px;
              min-width: calc(48px + 6 * (100vw - 320px) / 1600);
            }
          }
        }
      }
    }
  }
}
